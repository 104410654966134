import { Box, styled } from '@mui/material';
import { CustomCellEditorProps } from 'ag-grid-react';
import { addWeeks, isSameISOWeek, startOfWeek } from 'date-fns';
import { da } from 'date-fns/locale/da';
import dayjs from 'dayjs/esm';
import { FC, useCallback, useMemo, useState } from 'react';
import DatePicker, { DatePickerProps, registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { StyleTimelineOverviewRow } from 'src/domain/table/style-timeline-overview-row';
import { formatDateAsWeekYear, formatWeekYearAsDate } from 'src/utils/formatAsWeekYear';

registerLocale('da', {
    ...da,
    options: {
        ...da.options,
        weekStartsOn: 1,
    },
});

export function getDateOfISOWeek(week: number, year: number) {
    return dayjs().year(year).isoWeek(week).startOf('week').toDate();
}

function formatDateValue(date: Date | null) {
    if (!date) return;
    const day = dayjs(date);
    const week = day.isoWeek();
    const year = day.isoWeekYear();
    return Number(`${year}${week.toString().padStart(2, '0')}`);
}

export const WeekPickerCellEditor: FC<CustomCellEditorProps<StyleTimelineOverviewRow>> = ({
    value: valueStr,
    onValueChange,
    api,
    data,
}) => {
    const value = valueStr ? Number(valueStr) : null;

    const minExitWeek = addWeeks(new Date(formatWeekYearAsDate(data.startWeek)), 1);

    const selectedDate = useMemo(() => {
        if (!value || isNaN(value)) {
            return null;
        }
        return formatWeekYearAsDate(value);
    }, [value]);

    const handleDateChange = useCallback(
        (date: Date | null) => {
            if (date) {
                const weekYear = formatDateAsWeekYear(date);
                onValueChange(weekYear);
            } else {
                onValueChange(null);
            }
        },
        [onValueChange]
    );

    return (
        <WeekPicker
            minDate={startOfWeek(minExitWeek, { weekStartsOn: 1 })}
            selected={selectedDate}
            onChange={handleDateChange}
            onCalendarClose={() => {
                setTimeout(() => {
                    api.stopEditing();
                });
            }}
        />
    );
};

export const WeekRangePickerCellEditor: FC<CustomCellEditorProps> = ({ value, onValueChange, api }) => {
    const selectedDate = useMemo(() => {
        if (!value) {
            return null;
        }
        const year = Number(value.slice(0, 4));
        const week = Number(value.slice(4, 6));
        return getDateOfISOWeek(week, year);
    }, [value]);

    const [startDate, setStartDate] = useState<Date | undefined>(selectedDate ?? undefined);
    const [endDate, setEndDate] = useState<Date | undefined>();

    const handleDateChange = useCallback(
        (dates: [Date | null, Date | null]) => {
            const [start, end] = dates;

            setStartDate(start ?? undefined);
            setEndDate(end ?? undefined);

            const startValue = formatDateValue(start);
            const endValue = formatDateValue(end);

            if (start && end) {
                onValueChange(`${startValue}-${endValue}`);
            }
        },
        [onValueChange]
    );

    return (
        <WeekPicker
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            monthsShown={2}
            selectsRange
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            dayClassName={(date: any) =>
                startDate && isSameISOWeek(date, startDate) ? 'react-datepicker__day--selected' : ''
            }
            isClearable={true}
            onCalendarClose={() => {
                setTimeout(() => {
                    api.stopEditing();
                });
            }}
        />
    );
};

export const WeekPicker: FC<DatePickerProps> = ({ selected, ...props }) => {
    return (
        <DatePickerContainer>
            <StyledDatePicker
                portalId="root"
                popperClassName="ag-custom-component-popup react-datepicker__week-picker"
                dateFormat="dd/MM/yyyy"
                showWeekNumbers
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                locale={'da'}
                autoFocus
                dayClassName={(date) =>
                    selected && isSameISOWeek(date, selected) ? 'react-datepicker__day--selected' : ''
                }
                {...props}
            />
        </DatePickerContainer>
    );
};

const DatePickerContainer = styled(Box)`
  width: 100%;
`;

const StyledDatePicker = styled(DatePicker)`
  border: none;
  color: #787c8d;
  font-size: 0.9rem;
  width: 100%;
  box-sizing: border-box;
  height: 31px;
`;
