import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import 'src/extensions/array.extensions';
import 'src/extensions/string.extensions';
import App from './App';
import { msal } from './auth/azure-authentication-config';
import './dayjs';
import './styles/index.scss';
import { isInIframe } from './utils/iframe-utils';
import './wdyr';

if (isInIframe()) {
    // in iframe do nothing - this is to prevent errors when using silent auth flow
    // see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#block_iframe_reload
} else {
    const container = document.getElementById('root');

    if (!container) throw new Error('Could not get root element');

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                staleTime: 1000 * 60 * 5,
            },
        },
    });
    const root = createRoot(container);
    const msalInstance = new PublicClientApplication(msal.config);

    root.render(
        <StrictMode>
            <MsalProvider instance={msalInstance}>
                <QueryClientProvider client={queryClient}>
                    <App />
                </QueryClientProvider>
            </MsalProvider>
        </StrictMode>
    );
}
