import { useCallback } from 'react';
import { Cell } from 'src/components/organisms/DataTable/types';
import { exitWeekToLifeSpan, lifeSpanToExitWeek } from 'src/components/views/StyleTimeline/data/columns';
import { Module } from 'src/domain';
import { StyleTimelineOverviewRow } from 'src/domain/table/style-timeline-overview-row';
import { useUnsavedChangesModule } from '../unsaved-changes';

export const useStyleTimelineHandleCellValueChanges = (simulationData: StyleTimelineOverviewRow[]) => {
    const [unsavedChanges, setUnsavedChanges] = useUnsavedChangesModule<StyleTimelineOverviewRow[]>(
        Module.StyleTimeline
    );

    const callback = useCallback(
        (cells: Cell<unknown>[]) => {
            const newUnsavedChanges = [...(unsavedChanges ?? [])];

            cells.forEach((cell) => {
                // skip row group cells
                if (cell.rowId.toString().startsWith('row-group')) {
                    return;
                }

                if (cell.column) {
                    const rowIndexToUpdate = newUnsavedChanges.findIndex((x) => x.rowId === cell.rowId);
                    const simulationIndexToUpdate = simulationData.findIndex((x) => x.rowId === cell.rowId);

                    let newData = {
                        [cell.column]: cell.value,
                    };

                    if (cell.column === 'eligibleForSale') {
                        newData[cell.column] = Boolean(cell.value);
                    }

                    if (cell.column === 'storePlacement') {
                        const [storePlacement, storePlacementDetail] =
                            (cell.value as string | undefined)?.split('-') ?? [];

                        newData = {
                            storePlacement: storePlacement ?? null,
                            storePlacementDetail:
                                storePlacementDetail === 'undefined' || storePlacementDetail === 'null'
                                    ? null
                                    : (storePlacementDetail ?? null),
                        };
                    }

                    const startWeek = simulationData[simulationIndexToUpdate].startWeek;

                    if (isColumn<Pick<StyleTimelineOverviewRow, 'exitWeek' | 'exitWeekType'>>(cell, 'exitWeek')) {
                        const exitWeek = cell.value.exitWeek ? Number(cell.value.exitWeek) : null;
                        const exitWeekType = cell.value.exitWeekType;

                        newData = {
                            ...newData,
                            exitWeek: exitWeek,
                            exitWeekType,
                        };

                        newData = {
                            ...newData,
                            lifeSpan: exitWeekToLifeSpan(startWeek, exitWeek),
                        };
                    }

                    if (isColumn<Pick<StyleTimelineOverviewRow, 'lifeSpan' | 'exitWeekType'>>(cell, 'lifeSpan')) {
                        const lifeSpan = cell.value.lifeSpan ? Number(cell.value.lifeSpan) : null;
                        const exitWeekType = cell.value.exitWeekType;

                        newData = {
                            ...newData,
                            lifeSpan: lifeSpan,
                            exitWeekType,
                        };

                        newData = {
                            ...newData,
                            exitWeek: lifeSpan ? lifeSpanToExitWeek(startWeek, lifeSpan) : null,
                        };
                    }

                    if (rowIndexToUpdate >= 0) {
                        newUnsavedChanges[rowIndexToUpdate] = {
                            ...newUnsavedChanges?.[rowIndexToUpdate],
                            ...newData,
                        };
                    } else {
                        newUnsavedChanges.push({
                            ...simulationData[simulationIndexToUpdate],
                            ...newData,
                        });
                    }
                }
            });

            setUnsavedChanges(newUnsavedChanges);
        },
        [setUnsavedChanges, simulationData, unsavedChanges]
    );

    return callback;
};

// biome-ignore lint/suspicious/noExplicitAny: we need to use any
function isColumn<CellType>(cell: any, columnName: string): cell is Cell<CellType> {
    return cell.column === columnName;
}
