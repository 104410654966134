import { useCallback } from 'react';
import { Cell } from 'src/components/organisms/DataTable/types';
import { Inheritance, Module } from 'src/domain';
import { OptimalOptionCountRow } from 'src/domain/table/optimal-option-count.row';
import {
    OptimalOptionCountStylesPer100SQMLabel,
    OptimalOptionCountTotalLabel,
} from 'src/mapping/optimal-option-count.mapping';
import { mergeOptimalOptionCountRows } from 'src/utils/mergeRows';
import { useSetSelectedInheritance } from '../inheritance/useSetSelectedInheritance';
import { useTurnoverDetails } from '../turnover/useTurnoverDetails';
import { useUnsavedChangesModule } from '../unsaved-changes';
import { useActiveMonth } from '../useActiveMonth';
import { useOptimalOptionCountPlacementSimulationRows } from './simulation/useOptimalOptionCountPlacementSimulationRows';

export const useOptimalOptionCountHandleCellChanges = () => {
    const [_, setUnsavedChanges] = useUnsavedChangesModule<OptimalOptionCountRow[]>(Module.OptimalOptionCount);

    const [activeMonth] = useActiveMonth();

    const { data: typedData } = useOptimalOptionCountPlacementSimulationRows() ?? {};
    const { data: originalData } = useOptimalOptionCountPlacementSimulationRows();
    const { storeDetails, clusterDetails, partnerDetails } = useTurnoverDetails();

    const setSelectedInheritance = useSetSelectedInheritance();

    const callback = useCallback(
        (cells: Cell[]) => {
            if (!typedData || !originalData) {
                throw new Error('Data is missing!');
            }

            const mergedRows = mergeOptimalOptionCountRows(typedData, originalData);

            cells.forEach((cellValue) => {
                if (cellValue.value > 99999) return;
                const rowIndexToUpdate = mergedRows.findIndex((x) => x.id === cellValue.rowId);
                const optimalOptionCountRowIndexToUpdate = mergedRows.findIndex(
                    (x) => x.category === OptimalOptionCountTotalLabel
                );
                const stylesPerSqmRowIndexToUpdate = mergedRows.findIndex(
                    (x) => x.category === OptimalOptionCountStylesPer100SQMLabel
                );

                const fieldName: string | undefined = !cellValue.column ? activeMonth : cellValue.column?.split('.')[1];
                const size =
                    storeDetails?.storeSize ??
                    clusterDetails?.averageStoreSize ??
                    partnerDetails?.averageStoreSize ??
                    0;

                if (fieldName) {
                    if (cellValue.category === OptimalOptionCountStylesPer100SQMLabel) {
                        const newTotalValue = Math.round(cellValue.value * (size / 100));

                        mergedRows[optimalOptionCountRowIndexToUpdate] = {
                            ...mergedRows?.[optimalOptionCountRowIndexToUpdate],
                            columns: {
                                ...mergedRows?.[optimalOptionCountRowIndexToUpdate].columns,
                                [fieldName]: newTotalValue,
                            },
                        };
                        mergedRows[stylesPerSqmRowIndexToUpdate] = {
                            ...mergedRows?.[stylesPerSqmRowIndexToUpdate],
                            columns: {
                                ...mergedRows?.[stylesPerSqmRowIndexToUpdate].columns,
                                [fieldName]: cellValue.value,
                            },
                        };
                    } else if (cellValue.category === OptimalOptionCountTotalLabel) {
                        const newStylesPerSqmValue = Math.round(cellValue.value / (size / 100));
                        mergedRows[stylesPerSqmRowIndexToUpdate] = {
                            ...mergedRows?.[stylesPerSqmRowIndexToUpdate],
                            columns: {
                                ...mergedRows?.[stylesPerSqmRowIndexToUpdate].columns,
                                [fieldName]: newStylesPerSqmValue,
                            },
                        };
                        mergedRows[optimalOptionCountRowIndexToUpdate] = {
                            ...mergedRows?.[optimalOptionCountRowIndexToUpdate],
                            columns: {
                                ...mergedRows?.[optimalOptionCountRowIndexToUpdate].columns,
                                [fieldName]: cellValue.value,
                            },
                        };
                    } else {
                        mergedRows[rowIndexToUpdate] = {
                            ...mergedRows?.[rowIndexToUpdate],
                            columns: {
                                ...mergedRows?.[rowIndexToUpdate].columns,
                                [fieldName]: cellValue.value,
                            },
                        };
                    }
                }
            });
            const unsaved = [...mergedRows];

            setUnsavedChanges(unsaved);
            setSelectedInheritance(Inheritance.Typed);
        },
        [
            typedData,
            originalData,
            setUnsavedChanges,
            setSelectedInheritance,
            activeMonth,
            storeDetails?.storeSize,
            clusterDetails?.averageStoreSize,
            partnerDetails?.averageStoreSize,
        ]
    );

    return callback;
};
