import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { ScopeEnum } from 'src/domain/enums/split-level';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useScope } from 'src/hooks/useScope';
import { selectedPartnerVar } from 'src/infrastructure/local_state';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';

export const usePartnerCategorySplitQuery = () => {
    const [selectedPartner] = useReactiveVar(selectedPartnerVar);
    const partnerComposite = useCompositePartner(selectedPartner);
    const scope = useScope();

    const { data, error, loading } = useApiQuery('/api/categorysplit', 'get', {
        query: {
            entityId: Number(partnerComposite?.id),
            entityType: 'Partner',
        },
        enabled: !!partnerComposite?.id && (scope === ScopeEnum.PARTNER || scope === ScopeEnum.STORE),
    });

    return { data, error, loading } as const;
};
