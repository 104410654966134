import { Dexie, type EntityTable } from 'dexie';
import { useLiveQuery } from 'dexie-react-hooks';
import { useCallback } from 'react';

export interface UnsavedChange<TData> {
    key: string;
    changes: TData;
}

export interface UnsavedChangesDatabase extends Dexie {
    // biome-ignore lint/suspicious/noExplicitAny: we need to use any
    unsavedChanges: EntityTable<UnsavedChange<any>, 'key'>;
}

export function createUnsavedChangesDatabase() {
    const db = new Dexie('UnsavedChangesDatabase') as UnsavedChangesDatabase;
    db.version(1).stores({
        unsavedChanges: 'key, changes',
    });
    return db;
}

const db = createUnsavedChangesDatabase();

async function setUnsavedChanges<TData>(key: string, changes: TData) {
    await db.unsavedChanges.put({ key, changes });
}

// implementation
export function useUnsavedChanges<T>(
    key: string,
    defaultValue?: T
): [
    typeof defaultValue extends undefined ? T | undefined : T,
    (arg: T | ((state: T | undefined) => T)) => Promise<void>,
    () => Promise<UnsavedChange<T> | undefined>,
] {
    const getUnsavedChanges = useCallback(async () => {
        return db.unsavedChanges.where({ key }).first();
    }, [key]);

    const unsavedChanges = useLiveQuery(() => {
        if (key) {
            return getUnsavedChanges();
        }
    }, [getUnsavedChanges]);

    const setValue = useCallback(
        (arg: T | ((state: T) => T)): Promise<void> => {
            let value: T;
            if (arg instanceof Function) {
                value = arg(unsavedChanges?.changes ?? []);
            } else {
                value = arg;
            }

            return setUnsavedChanges(key, value);
        },
        [key, unsavedChanges]
    );

    return [unsavedChanges?.changes ?? defaultValue, setValue, getUnsavedChanges];
}
