import { Alert, AlertProps, Collapse } from '@mui/material';
import { FC, useRef } from 'react';
import { Module, ScopeEnum } from 'src/domain';
import { useStockManagementHasUnsavedChanges } from 'src/hooks/stock-management/useStockManagementHasUnsavedChanges';
import { useHasUnsavedChangesGenderProductline } from 'src/hooks/unsaved-changes/useHasUnsavedChangesGenderProductline';
import { useCurrentModule } from 'src/hooks/useCurrentModule';
import { useScope } from 'src/hooks/useScope';

export const StoresOverwrittenAlert: FC<AlertProps> = (props) => {
    const currentModule = useCurrentModule();
    const scope = useScope();
    const hasUnsavedChangesOtherModules = useHasUnsavedChangesGenderProductline();
    const stockManagementHasUnsavedChanges = useStockManagementHasUnsavedChanges();

    const hasUnsavedChanges =
        currentModule === Module.StockManagement ? stockManagementHasUnsavedChanges : hasUnsavedChangesOtherModules;

    const isCountry = scope === ScopeEnum.PARTNER;
    const isCluster = scope === ScopeEnum.CLUSTER;

    const shouldShow = hasUnsavedChanges && (isCountry || isCluster);

    const alertRef = useRef<HTMLDivElement>(null);

    return (
        <div style={{ height: alertRef.current?.clientHeight }}>
            <Collapse in={shouldShow} timeout={1000}>
                <Alert {...props} severity="warning" ref={alertRef}>
                    Changes made to this table will overwrite values in child stores!
                </Alert>
            </Collapse>
        </div>
    );
};
