import { Button, Select, SelectChangeEvent, Stack, Typography, styled } from '@mui/material';
import { IDoesFilterPassParams, ValueGetterParams } from 'ag-grid-community';
import { CustomFilterProps, useGridFilter } from 'ag-grid-react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DatePickerProps } from 'react-datepicker';
import { StyleTimelineExitWeekType } from 'src/infrastructure/rest-api/style-timeline';
import { ComparatorType, commonComparators, doesDateFilterPass } from 'src/utils/dateFilterUtils';

export interface DateFilterBaseProps extends CustomFilterProps {
    dateFormatter: (date: Date) => number;
    DatePickerComponent: React.ComponentType<DatePickerProps>;
    formatDate: (date: number) => string;
    getEndExcludeDateIntervals: (startDate: Date | null) => { start: Date; end: Date }[];
    supportsInfinite?: boolean;
}

export const DateFilterBase: FC<DateFilterBaseProps> = ({
    onModelChange,
    colDef,
    dateFormatter,
    DatePickerComponent,
    formatDate,
    getEndExcludeDateIntervals,
    supportsInfinite = false,
}) => {
    const comparators = useMemo(() => {
        if (supportsInfinite) {
            return [...commonComparators, { label: 'Is indefinitely available', value: ComparatorType.isInfinite }];
        }
        return commonComparators;
    }, [supportsInfinite]);

    const [selectedComparator, setSelectedComparator] = useState(comparators[0]);
    const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

    const clearFilter = useCallback(() => {
        setSelectedStartDate(null);
        setSelectedEndDate(null);
        setSelectedComparator(comparators[0]);
        onModelChange(null);
    }, [comparators, onModelChange]);

    const onStartDateChange = useCallback((date: Date | null) => {
        setSelectedStartDate(date);
    }, []);

    const onEndDateChange = useCallback((date: Date | null) => {
        setSelectedEndDate(date);
    }, []);

    const handleComparatorChange = useCallback(
        (event: SelectChangeEvent) => {
            const selectedComparator = comparators.find((x) => x.value.toString() === event.target.value);
            setSelectedComparator(selectedComparator ?? comparators[0]);
        },
        [comparators]
    );

    const isFilterActive = useMemo(() => {
        switch (selectedComparator.value) {
            case ComparatorType.between:
                return !!selectedStartDate && !!selectedEndDate;
            case ComparatorType.isEmpty:
            case ComparatorType.isNotEmpty:
            case ComparatorType.isInfinite:
                return true;
            default:
                return !!selectedStartDate;
        }
    }, [selectedComparator.value, selectedEndDate, selectedStartDate]);

    const doesFilterPass = useCallback(
        (params: IDoesFilterPassParams) => {
            const valueGetter = colDef.valueGetter;
            let value =
                typeof valueGetter === 'function'
                    ? valueGetter({ data: params.data } as unknown as ValueGetterParams)
                    : Number(params.data[colDef.field ?? '']);

            let isInfinite = false;
            if (value && typeof value === 'object') {
                isInfinite = (value.exitWeekType as StyleTimelineExitWeekType) === 'INFINITE';
                value = value.exitWeek;
            }

            return doesDateFilterPass(
                value,
                selectedComparator.value,
                selectedStartDate,
                selectedEndDate,
                dateFormatter,
                isInfinite
            );
        },
        [colDef.valueGetter, colDef.field, selectedComparator.value, selectedStartDate, selectedEndDate, dateFormatter]
    );

    const endExcludeDateIntervals = useMemo(
        () => getEndExcludeDateIntervals(selectedStartDate),
        [selectedStartDate, getEndExcludeDateIntervals]
    );

    useEffect(() => {
        if (isFilterActive) {
            onModelChange({ type: selectedComparator, startDate: selectedStartDate, endDate: selectedEndDate });
        } else {
            onModelChange(null);
        }
    }, [onModelChange, selectedComparator, selectedStartDate, selectedEndDate, isFilterActive]);

    useGridFilter({
        doesFilterPass,
    });

    return (
        <Stack p={1} gap={2} sx={{ position: 'relative' }}>
            {isFilterActive && <ClearButton onClick={clearFilter}>Clear</ClearButton>}
            <Select
                data-testid="comparatorSelector"
                native={true}
                onChange={handleComparatorChange}
                value={selectedComparator?.value.toString()}
                fullWidth
                size="small"
            >
                {comparators.map((comparator) => (
                    <option key={comparator.value} value={comparator.value.toString()}>
                        {comparator.label}
                    </option>
                ))}
            </Select>
            {![ComparatorType.isEmpty, ComparatorType.isNotEmpty, ComparatorType.isInfinite].includes(
                selectedComparator.value
            ) && (
                <DatePickerComponent
                    selected={selectedStartDate}
                    onChange={onStartDateChange}
                    autoFocus={false}
                    customInput={
                        <DatePickerButton fullWidth data-testid="filterStartDate">
                            {selectedStartDate ? formatDate(dateFormatter(selectedStartDate)) : 'Choose date'}
                        </DatePickerButton>
                    }
                />
            )}
            {selectedComparator.value === ComparatorType.between && (
                <>
                    <Typography textAlign={'center'}>AND</Typography>
                    <DatePickerComponent
                        disabled={!selectedStartDate}
                        selected={selectedEndDate}
                        excludeDateIntervals={endExcludeDateIntervals}
                        onChange={onEndDateChange}
                        autoFocus={false}
                        customInput={
                            <DatePickerButton fullWidth data-testid="filterEndDate">
                                {selectedEndDate ? formatDate(dateFormatter(selectedEndDate)) : 'Choose date'}
                            </DatePickerButton>
                        }
                    />
                </>
            )}
        </Stack>
    );
};

const DatePickerButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.grey[300],
    color: `${theme.palette.grey[700]} !important`,
    ':hover': {
        backgroundColor: theme.palette.grey[400],
    },
    cursor: 'pointer',
}));

const ClearButton = styled('a')(({ theme }) => ({
    color: theme.palette.primary.main,
    marginLeft: 'auto',
    cursor: 'pointer',
}));
