import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { ScopeEnum } from 'src/domain/enums/split-level';
import { useScope } from 'src/hooks/useScope';
import { selectedPartnerVar } from 'src/infrastructure/local_state';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';
import { useClusterId } from '../../cluster/useClusterId';

export const useClusterCategorySplitQuery = () => {
    const [selectedPartner] = useReactiveVar(selectedPartnerVar);
    const clusterId = useClusterId(selectedPartner);
    const scope = useScope();

    const { data, error, loading } = useApiQuery('/api/categorysplit', 'get', {
        query: {
            entityId: Number(clusterId),
            entityType: 'Cluster',
        },
        enabled: !!clusterId && (scope === ScopeEnum.CLUSTER || scope === ScopeEnum.STORE),
    });

    return { data, error, loading } as const;
};
