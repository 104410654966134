import { useCallback, useState } from 'react';
import { Inheritance, Module, ScopeEnum } from 'src/domain';
import { TableOverviewRow } from 'src/domain/table/table-overview-row';
import { useChangesSnackbar, useScope } from 'src/hooks';
import { useDiscardChangesGenderProductline } from 'src/hooks/discard-changes/useDiscardChangesGenderProductline';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useUpdateSplitLevel } from 'src/hooks/split-levels/useUpdateSplitLevel';
import { useCurrentId } from 'src/hooks/useCurrentId';
import { useEntityType } from 'src/hooks/useEntityType';
import { UpdateCategorySplitInput } from 'src/infrastructure/rest-api/api-types';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { useCategorySplitOverviewSimulationRows } from '../simulation/useCategorySplitOverviewSimulationRows';

function valueMapper(row: TableOverviewRow) {
    return Object.keys(row.columns).reduce<UpdateCategorySplitInput[]>((acc, key) => {
        if (key.endsWith('Id')) {
            return acc;
        }

        const id = row.columns[`${key}Id`];
        const value = row.columns[key];

        acc.push({
            id: id,
            splitPercent: value,
        });

        return acc;
    }, []);
}

export const useCategorySplitSaveChanges = () => {
    const currentId = useCurrentId();
    const scope = useScope();
    const entityType = useEntityType();
    const compositePartner = useCompositePartner();

    const [mutateCategorySplit] = useApiMutation('/api/categorysplit', 'put', {
        update(data, _variables, queryClient) {
            const query = { entityId: Number(currentId), entityType };
            if (scope === ScopeEnum.PARTNER) {
                query['entityId'] = Number(compositePartner?.id);
            }
            queryClient.setQueryData(['/api/categorysplit', query], data);
            queryClient.invalidateQueries({
                queryKey: ['/api/inheritance', query],
            });
        },
    });

    const { updateSplitLevel: updateStoreSplitLevel } = useUpdateSplitLevel();

    const showSnackbar = useChangesSnackbar();
    const discardChanges = useDiscardChangesGenderProductline(Module.CategorySplit);

    const { data: valuesToUpdate } = useCategorySplitOverviewSimulationRows();

    const [isUpdating, setIsUpdating] = useState(false);

    const sendUpdateQuery = useCallback(async () => {
        if (!valuesToUpdate) {
            return;
        }

        const payload = valuesToUpdate.flatMap(valueMapper);

        if (scope === ScopeEnum.STORE) {
            await updateStoreSplitLevel(Inheritance.Typed);
        }

        return mutateCategorySplit({
            body: payload,
        });
    }, [mutateCategorySplit, scope, updateStoreSplitLevel, valuesToUpdate]);

    const updateCategorySplit = useCallback(async () => {
        setIsUpdating(true);

        try {
            const response = await sendUpdateQuery();
            discardChanges();
            showSnackbar();
            return response;
        } catch {
            showSnackbar(true);
        } finally {
            setIsUpdating(false);
        }
    }, [discardChanges, sendUpdateQuery, showSnackbar]);

    return [updateCategorySplit, { loading: isUpdating }] as const;
};
