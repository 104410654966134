import { useCallback, useState } from 'react';
import { Inheritance, Module, ScopeEnum } from 'src/domain';
import { TableOverviewRow } from 'src/domain/table/table-overview-row';
import { useChangesSnackbar, useScope } from 'src/hooks';
import { useDiscardChangesGenderProductline } from 'src/hooks/discard-changes/useDiscardChangesGenderProductline';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useUpdateSplitLevel } from 'src/hooks/split-levels/useUpdateSplitLevel';
import { useCurrentId } from 'src/hooks/useCurrentId';
import { useEntityType } from 'src/hooks/useEntityType';
import { components } from 'src/infrastructure/rest-api/generated';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { useMarkdownOverviewSimulationRows } from '../simulation/useMarkdownOverviewSimulationRows';

type UpdateMarkDownInput = components['schemas']['UpdateMarkdownInput'];

function valueMapper(row: TableOverviewRow): UpdateMarkDownInput[] {
    return Object.keys(row.columns).reduce<UpdateMarkDownInput[]>((acc, key) => {
        if (key.endsWith('Id') || key.endsWith('PlannedSplit')) return acc;

        const id = row.columns[`${key}Id`];
        const value = row.columns[key];

        acc.push({
            id: id,
            markdownPct: value,
        });

        return acc;
    }, []);
}

export const useMarkdownSaveChanges = () => {
    const currentId = useCurrentId();
    const compositePartner = useCompositePartner();
    const entityType = useEntityType();
    const scope = useScope();

    const [mutateMarkdown] = useApiMutation('/api/markdown', 'put', {
        update(data, _variables, queryClient) {
            const query = { entityId: Number(currentId), entityType };
            if (scope === ScopeEnum.PARTNER) {
                query['entityId'] = Number(compositePartner?.id);
            }
            queryClient.setQueryData(['/api/markdown', query], data);
            queryClient.invalidateQueries({
                queryKey: ['/api/inheritance', query],
            });
        },
    });

    const { updateSplitLevel: updateStoreSplitLevel } = useUpdateSplitLevel();

    const showSnackbar = useChangesSnackbar();
    const discardChanges = useDiscardChangesGenderProductline(Module.Markdown);

    const { data: valuesToUpdate } = useMarkdownOverviewSimulationRows();
    const [isUpdating, setIsUpdating] = useState(false);

    const sendUpdateQuery = useCallback(async () => {
        if (!valuesToUpdate) {
            return;
        }

        const payload = valuesToUpdate.flatMap(valueMapper);

        if (scope === ScopeEnum.STORE) {
            await updateStoreSplitLevel(Inheritance.Typed);
        }

        return mutateMarkdown({
            body: payload,
        });
    }, [scope, mutateMarkdown, updateStoreSplitLevel, valuesToUpdate]);

    const updateMarkdown = useCallback(async () => {
        setIsUpdating(true);

        try {
            const response = await sendUpdateQuery();
            discardChanges();
            showSnackbar();
            return response;
        } catch {
            showSnackbar(true);
        } finally {
            setIsUpdating(false);
        }
    }, [discardChanges, sendUpdateQuery, showSnackbar]);

    return [updateMarkdown, { loading: isUpdating }] as const;
};
