import { useMemo } from 'react';
import { CategorySplitLevelQuery } from 'src/domain/models/category-split/CategorySplitLevelQuery';
import { useInitializeIsCategorySplitHistorical } from 'src/hooks/historical/useIsCategorySplitHistorical';
import { useClusterCategorySplitQuery } from './useClusterCategorySplitQuery';
import { usePartnerCategorySplitQuery } from './usePartnerCategorySplitQuery';
import { useStoreCategorySplitQuery } from './useStoreCategorySplitQuery';

export const useCategorySplitLevelQuery = (): CategorySplitLevelQuery | undefined => {
    const { data: storeCategorySplit, error: storeError, loading: storeLoading } = useStoreCategorySplitQuery();
    const { data: clusterCategorySplit, error: clusterError, loading: clusterLoading } = useClusterCategorySplitQuery();
    const { data: partnerCategorySplit, error: partnerError, loading: partnerLoading } = usePartnerCategorySplitQuery();

    const data = useMemo(() => {
        return {
            storeCategorySplit,
            clusterCategorySplit,
            partnerCategorySplit,
        };
    }, [storeCategorySplit, clusterCategorySplit, partnerCategorySplit]);

    useInitializeIsCategorySplitHistorical(data);

    return {
        data,
        error: storeError ?? clusterError ?? partnerError,
        loading: storeLoading ?? clusterLoading ?? partnerLoading,
    };
};
