import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, Stack, Tooltip } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { MathJax } from 'better-react-mathjax';
import { FC, useMemo } from 'react';
import Spacer from 'src/components/atoms/Spacer';
import { Module, Spacings } from 'src/domain/enums';
import { useCurrentModule } from 'src/hooks/useCurrentModule';
import { LockIconTip } from './LockIconTip';
import { Tip } from './Tip';
import { ToolTipText } from './ToolTipText';

export const InheritanceHelpStore: FC = () => {
    const currentModule = useCurrentModule();

    const fccHelp =
        'There are two different FCC budgets available to inherit from. FCC budgets are created and maintained by Bestseller Finance.';
    const noosHelp =
        'There are three different historical Noos inheritance options. They behave like any other historical inheritance. The difference between the three are:';
    const turnover_rollsToFixed =
        'A store inheriting from "Fixed" has had changes made to its values by a user. All values are fixed and will not update automatically. New months will have last years "Fixed" values.';
    const hasHistoricals_rollsToFixed_CellLock =
        'A store inheriting from "Fixed" has had changes made to its values by a user. Cells with user entries will be fixed. All other cells will update automatically. New months will have last years "Fixed" values.';
    const hasNoHistoricals_rollsToFixed_NoLock =
        'A store inheriting from "Fixed" has had changes made to its values by a user. This module has no historical values. All values are fixed and will not update automatically. New months will have last years "Fixed" values.';
    const hasHistoricals_rollsToHistorical_CellLock =
        'A store inheriting from "Typed" has had changes made to its values by a user. Cells with user entries will be locked. All other cells will update automatically. New months will have historical values.';
    const hasHistoricals_rollsToHistorical_ColumnLock =
        'A store inheriting from "Typed" has had changes made to its values by a user. Columns with user entries will be locked. All other columns will update automatically. New months will have historical values.';

    const typedDescription = useMemo(() => {
        switch (currentModule) {
            //It's own thing!
            case Module.Turnover:
                return turnover_rollsToFixed;
            //Rolls to Historicals - Column lock
            case Module.CategorySplit:
                return hasHistoricals_rollsToHistorical_ColumnLock;
            case Module.GenderSplit:
                return hasHistoricals_rollsToHistorical_ColumnLock;
            case Module.ProductLineSplit:
                return hasHistoricals_rollsToHistorical_ColumnLock;
            //Rolls to Historicals - Cell lock
            case Module.NoosShare:
                return hasHistoricals_rollsToHistorical_CellLock;
            //Rolls to Fixed (Cannibalization)
            case Module.Markup:
                return hasHistoricals_rollsToFixed_CellLock;
            case Module.Markdown:
                return hasHistoricals_rollsToFixed_CellLock;
            case Module.WeekCover:
                return hasNoHistoricals_rollsToFixed_NoLock;
            case Module.DeliveryProfile:
                return hasNoHistoricals_rollsToFixed_NoLock;
        }
        // Modules without Inheritance
        return null;
    }, [currentModule]);

    const typedOrFixed = useMemo(() => {
        switch (currentModule) {
            case Module.Turnover:
            case Module.Markup:
            case Module.Markdown:
            case Module.WeekCover:
            case Module.DeliveryProfile:
                return 'FIXED:';
        }
        return 'TYPED';
    }, [currentModule]);

    const showHistorical = useMemo(() => {
        switch (currentModule) {
            case Module.WeekCover:
            case Module.DeliveryProfile:
                return null;
        }
        return (
            <Typography noWrap={false}>
                <Typography component="span" fontWeight={'bold'}>
                    HISTORICAL:
                </Typography>{' '}
                A store inheriting from historical will automatically update its values monthly to the latest historical
                values.
            </Typography>
        );
    }, [currentModule]);

    const showNoosHelp = useMemo(() => {
        switch (currentModule) {
            case Module.NoosShare:
                return (
                    <Stack spacing={2}>
                        <Typography noWrap={false}>
                            <Typography component="span" fontWeight={'bold'}>
                                NOOS:
                            </Typography>{' '}
                            {noosHelp}
                        </Typography>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography fontWeight={'normal'}>NOOS Share:</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component="span" noWrap={false}>
                                    Is based on historical noos sales in the store. The calculation is based on the
                                    following formula: <Spacer spacing={Spacings.xSmall} vertical />
                                    <MathJax inline>
                                        {
                                            '\\({\\text{Noos category sales} \\over \\text{Category sales}} \\times 100\\)'
                                        }
                                    </MathJax>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel2a-header"
                            >
                                <Typography fontWeight={'normal'}>Auto replenishment share:</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component="span" noWrap={false}>
                                    Is based on historical noos sales in the store. Only sales marked as auto
                                    replenished (Demand Driven Replenishment) will be included here. The calculation is
                                    based on the following formula: <Spacer spacing={Spacings.xSmall} vertical />
                                    <MathJax inline>
                                        {'\\({\\text{DDR category sales} \\over \\text{Category sales}} \\times 100\\)'}
                                    </MathJax>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel2a-header"
                            >
                                <Typography fontWeight={'normal'}>Auto replenishment order share:</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component="span" noWrap={false}>
                                    Is based on historical noos sales orders for the store. Only automatically generated
                                    sales orders will be included here. The calculation is based on the value of
                                    completed orders filtered with Sales Channel L2:{' '}
                                    <Spacer spacing={Spacings.xSmall} vertical />
                                    <MathJax inline>
                                        {
                                            '\\({\\text{Auto Replenishment orders} \\over \\text{COGS from retail sales data}} \\times 100\\)'
                                        }
                                    </MathJax>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Stack>
                );
        }
    }, [currentModule]);

    const showFCCHelp = useMemo(() => {
        switch (currentModule) {
            case Module.Turnover:
                return (
                    <Stack spacing={2}>
                        <Typography noWrap={false}>
                            <Typography component="span" fontWeight={'bold'}>
                                FCC BUDGETS:
                            </Typography>{' '}
                            {fccHelp}
                        </Typography>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography fontWeight={'normal'}>FCC Initial</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component="span" noWrap={false}>
                                    ...is created by Bestseller finance for a financial year. FCC Initial will remain
                                    the same for the entire financial year.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel2a-header"
                            >
                                <Typography fontWeight={'normal'}>FCC Revised</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component="span" noWrap={false}>
                                    ...is created by Bestseller finance for a financial year. FCC Revised budget can be
                                    updated by Bestseller finance during financial the year.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Stack>
                );
        }
    }, [currentModule]);

    return (
        <Stack spacing={2}>
            <Typography noWrap={false}>
                Inheritance determines where your store gets its values from, and is set on a{' '}
                <Tooltip title="Example: category split and NOOS share are different modules">
                    <ToolTipText>module</ToolTipText>
                </Tooltip>{' '}
                level. Stores will by default inherit from historical data.
            </Typography>
            <Typography noWrap={false}>
                A store has different data sets it can inherit from, the most common being:
            </Typography>
            <Typography noWrap={false}>
                <Typography component="span" noWrap={false} fontWeight={'bold'}>
                    COUNTRY/CLUSTER:
                </Typography>{' '}
                A store inheriting from its Country/Cluster will have the same values as the given country/cluster, and
                will dynamically update itself to always display the latest values.{' '}
            </Typography>
            <Tip>
                Updating the values on a country/cluster level will affect all stores inheriting from that level! This
                means you don&apos;t have to manually update each store.
            </Tip>
            {showHistorical}
            {showNoosHelp}
            <Typography noWrap={false}>
                <Typography component="span" fontWeight={'bold'}>
                    {typedOrFixed}
                </Typography>{' '}
                {typedDescription}
            </Typography>

            <LockIconTip />

            {showFCCHelp}
        </Stack>
    );
};
