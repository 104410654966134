import { useMemo } from 'react';
import { Inheritance } from 'src/domain/enums/inheritance';
import { Module } from 'src/domain/enums/module';
import { CategorySplit } from 'src/domain/models/category-split/CategorySplit';
import { useGenderId } from 'src/hooks/gender/useGenderId';
import { useDefaultInheritance } from 'src/hooks/inheritance';
import { useProductLineId } from 'src/hooks/productline/useProductLineId';
import { useCategorySplitLevelQuery } from './useCategorySplitLevelQuery';

export const useCategorySplitInheritanceQuery = (
    inheritance?: Inheritance,
    genderId?: number,
    productLineId?: number
) => {
    const { data, error, loading } = useCategorySplitLevelQuery() ?? {};
    const moduleInheritance = useDefaultInheritance(Module.CategorySplit);
    const selectedInheritance = inheritance ?? moduleInheritance;

    const moduleGenderId = useGenderId();
    const moduleProductLineId = useProductLineId();

    const selectedGenderId = genderId === null ? undefined : (genderId ?? moduleGenderId);
    const selectedProductLineId = productLineId === null ? undefined : (productLineId ?? moduleProductLineId);

    const sanitizedData: CategorySplit = useMemo(() => {
        if (!data) return;

        switch (selectedInheritance) {
            case Inheritance.Typed:
            case Inheritance.Historical:
                return data.storeCategorySplit ?? data.clusterCategorySplit ?? data.partnerCategorySplit;

            case Inheritance.Cluster:
                return data.clusterCategorySplit;

            case Inheritance.Partner:
                return data.partnerCategorySplit;
        }
    }, [data, selectedInheritance]);

    const filteredData: CategorySplit = useMemo(() => {
        if (!sanitizedData) return;
        return sanitizedData.filter((item) => {
            const isGenderMatch = typeof selectedGenderId === 'number' ? item.genderId === selectedGenderId : true;
            const isProductLineMatch =
                typeof selectedProductLineId === 'number' ? item.productlineGroupId === selectedProductLineId : true;
            return isGenderMatch && isProductLineMatch;
        });
    }, [sanitizedData, selectedGenderId, selectedProductLineId]);

    return { expected: filteredData, sanitizedData, data, error, loading: !!loading };
};
