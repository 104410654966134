import { useContext, useEffect, useMemo } from 'react';
import { Module, ScopeEnum } from 'src/domain';
import { NoosShareData } from '../../domain/models/noos-share/NoosShareData';
import { useScope } from '../useScope';
import { IsHistoricalContext } from './IsHistoricalContext';

export const useIsNoosShareHistorical = (data: NoosShareData | undefined) => {
    const scope = useScope();

    const isTyped = useMemo(() => {
        switch (scope) {
            case ScopeEnum.STORE:
                return data?.storeNoosShare?.data?.some((x) => !!x?.modifiedByUser);

            case ScopeEnum.CLUSTER:
                return data?.clusterNoosShare?.data?.some((x) => !!x?.modifiedByUser);

            case ScopeEnum.PARTNER:
                return data?.partnerNoosShare?.data?.some((x) => !!x?.modifiedByUser);
        }
    }, [data?.storeNoosShare?.data, data?.partnerNoosShare?.data, data?.clusterNoosShare?.data, scope]);

    // invert isTyped but keep undefined
    if (isTyped === true) return false;
    if (isTyped === false) return true;
    return undefined;
};

export const useInitializeIsNoosShareHistorical = (data: NoosShareData | undefined) => {
    const [isHistoricalMap, setIsHistoricalMap] = useContext(IsHistoricalContext);

    const isNoosShareHistorical = useIsNoosShareHistorical(data);

    useEffect(() => {
        if (isNoosShareHistorical === undefined) return;
        if (isHistoricalMap.get(Module.NoosShare) === isNoosShareHistorical) return;

        isHistoricalMap.set(Module.NoosShare, isNoosShareHistorical);
        setIsHistoricalMap(new Map(isHistoricalMap));
    }, [isNoosShareHistorical, isHistoricalMap, setIsHistoricalMap]);
};
