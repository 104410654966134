import { isNumber } from 'lodash';
import { useCallback } from 'react';
import { Cell } from 'src/components/organisms/DataTable/types';
import { CategoryOverviewRow, Inheritance, Module } from 'src/domain';
import { combineGenderAndProductline } from 'src/utils/combineGenderAndProductline';
import { useGender } from '../gender';
import { useSetSelectedInheritance } from '../inheritance';
import { useProductLine } from '../productline';
import { useStoreId } from '../store';
import { useUnsavedChangesModule } from '../unsaved-changes';
import { useWeekCoverOverviewSimulationRows } from './useWeekCoverOverviewSimulationRows';

export const useHandleCellValueChangedWeekCover = () => {
    const { data } = useWeekCoverOverviewSimulationRows();

    const [unsavedChanges, setUnsavedChanges] = useUnsavedChangesModule<Record<string, CategoryOverviewRow[]>>(
        Module.WeekCover
    );

    const storeId = useStoreId();
    const gender = useGender();
    const productLine = useProductLine();
    const setSelectedInheritance = useSetSelectedInheritance();

    const callback = useCallback(
        (cellValues: Cell[]) => {
            const markup = data?.isEmpty() ? null : data;

            if (!markup) throw new Error(`Current split data is missing: ${markup}`);
            if (!storeId) throw new Error(`Store ID is missing: ${storeId}`);

            cellValues.forEach((cellValue) => {
                const rowIndexToUpdate = markup.findIndex((x) => x.id === cellValue.rowId);
                const month: string | undefined = cellValue.column?.split('.')[1];

                const valueIsValid = !isNaN(cellValue.value) && isNumber(Number(cellValue.value));

                if (month) {
                    markup[rowIndexToUpdate] = {
                        ...markup?.[rowIndexToUpdate],
                        columns: {
                            ...markup?.[rowIndexToUpdate].columns,
                            [month]: valueIsValid ? cellValue.value : 0,
                        },
                    };
                }
            });

            const genderProductLineKey = combineGenderAndProductline(gender, productLine?.name);
            const unsaved: Record<string, CategoryOverviewRow[]> = {
                ...unsavedChanges,
                ...{ [genderProductLineKey]: [...markup] },
            };

            setUnsavedChanges(unsaved);
            setSelectedInheritance(Inheritance.Typed);
        },
        [data, storeId, gender, productLine?.name, unsavedChanges, setUnsavedChanges, setSelectedInheritance]
    );

    return callback;
};
