import { useMemo } from 'react';
import { useSelectedStockManagementTable } from 'src/hooks';
import { useCurrentId } from 'src/hooks/useCurrentId';

export const useSelectedTable = () => {
    const [selectedTable] = useSelectedStockManagementTable();
    const id = useCurrentId();

    // set table when default updates
    return useMemo(() => {
        if (id) {
            return selectedTable.get(id);
        }
    }, [selectedTable, id]);
};
