import { useMsal } from '@azure/msal-react';
import { datadogRum } from '@datadog/browser-rum';
import { useCallback } from 'react';
import { msal } from 'src/auth/azure-authentication-config';

export const useLogin = () => {
    const { instance } = useMsal();

    const login = useCallback(
        () =>
            instance.loginRedirect(msal.loginRequest).catch((e) => {
                datadogRum.addError(e);
            }),
        [instance]
    );

    return login;
};
