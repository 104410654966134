import { useCallback } from 'react';
import { Cell } from 'src/components/organisms/DataTable/types';
import { ArrayElement, Inheritance, InheritanceModule, Module } from 'src/domain';
import { InheritanceSettingsRow } from 'src/domain/table/inheritance-settings.row';
import { mergeMap } from 'src/utils/mergeMap';
import { useUnsavedChangesModule } from '../unsaved-changes';
import { useInheritanceSettingsSimulationRows } from './simulation/useInheritanceSettingsSimulationRows';
import { useInheritanceSettingsRows } from './table/useInheritanceSettingsRows';

export const useInheritanceSettingsHandleCellChanges = () => {
    const [_, setUnsavedChanges] = useUnsavedChangesModule<InheritanceSettingsRow[]>(Module.InheritanceSettings);

    const { data: typedData } = useInheritanceSettingsSimulationRows() ?? {};
    const { data: originalData } = useInheritanceSettingsRows();

    const callback = useCallback(
        (cells: Cell[]) => {
            if (!typedData || !originalData) {
                throw new Error('Data is missing!');
            }

            const mergedRows: InheritanceSettingsRow[] = mergeMap(
                [typedData, originalData],
                ([expectedRow, currentRow]) => {
                    const currentValues = Object.values(InheritanceModule)
                        .map((x) => x as ArrayElement<typeof InheritanceModule>)
                        .reduce<InheritanceSettingsRow>((acc, module) => {
                            if (
                                currentRow?.[module] &&
                                expectedRow?.[module] &&
                                currentRow?.[module] !== expectedRow?.[module]
                            ) {
                                return { ...acc, [module]: expectedRow?.[module] };
                            }
                            return { ...acc, [module]: currentRow?.[module] ?? -1 };
                        }, {} as InheritanceSettingsRow);

                    if (!expectedRow) {
                        return {
                            ...currentRow,
                            ...currentValues,
                        };
                    }

                    return {
                        ...expectedRow,
                        ...currentValues,
                    };
                }
            );

            cells.forEach((cellValue) => {
                const rowIndexToUpdate = mergedRows.findIndex((x) => x.id === cellValue.rowId);
                const module = cellValue.column as keyof InheritanceSettingsRow;

                if (!module) return;

                if (module === 'noosShare') {
                    switch (cellValue.value) {
                        case Inheritance.Typed:
                        case Inheritance.Cluster:
                        case Inheritance.Partner:
                            // update inheritance
                            mergedRows[rowIndexToUpdate] = {
                                ...mergedRows?.[rowIndexToUpdate],
                                [module]: {
                                    inheritance: cellValue.value,
                                    noosActualInheritance:
                                        mergedRows[rowIndexToUpdate]?.noosShare?.noosActualInheritance,
                                },
                            };
                            break;
                        case Inheritance.Historical:
                        case Inheritance.NoosDDR:
                        case Inheritance.NoosHistoricalOrders:
                            // update noos actual inheritance
                            mergedRows[rowIndexToUpdate] = {
                                ...mergedRows?.[rowIndexToUpdate],
                                [module]: {
                                    inheritance: Inheritance.Historical,
                                    noosActualInheritance: cellValue.value,
                                },
                            };
                            break;
                        default:
                            throw new Error('Invalid inheritance value');
                    }
                } else {
                    mergedRows[rowIndexToUpdate] = {
                        ...mergedRows?.[rowIndexToUpdate],
                        [module]: cellValue.value,
                    };
                }
            });

            setUnsavedChanges([...mergedRows]);
        },
        [originalData, setUnsavedChanges, typedData]
    );

    return callback;
};
