import { useCallback, useState } from 'react';
import { Inheritance, Module, ScopeEnum, TableOverviewRow } from 'src/domain';
import { useChangesSnackbar, useScope } from 'src/hooks';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useUpdateSplitLevel } from 'src/hooks/split-levels/useUpdateSplitLevel';
import { useCurrentId } from 'src/hooks/useCurrentId';
import { useEntityType } from 'src/hooks/useEntityType';
import { UpdateProductLineSplitInput } from 'src/infrastructure/rest-api/api-types';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { useProductLineSplitOverviewSimulationRows } from '../simulation/useProductLineSplitOverviewSimulationRows';

function valueMapper(row: TableOverviewRow) {
    return Object.keys(row.columns).reduce<UpdateProductLineSplitInput[]>((acc, key) => {
        if (key.endsWith('Id')) return acc;

        const id = row.columns[`${key}Id`];
        const value = row.columns[key];

        acc.push({
            id: id,
            splitPercent: value,
        });

        return acc;
    }, []);
}

export const useProductLineSplitSaveChanges = () => {
    const currentId = useCurrentId();
    const scope = useScope();
    const entityType = useEntityType();
    const compositePartner = useCompositePartner();

    const [mutateProductlineSplit] = useApiMutation('/api/productlinesplit', 'put', {
        update(data, _variables, queryClient) {
            const query = { entityId: Number(currentId), entityType };
            if (scope === ScopeEnum.PARTNER) {
                query.entityId = Number(compositePartner?.id);
            }
            queryClient.setQueryData(['/api/productlinesplit', query], data);
            queryClient.invalidateQueries({
                queryKey: ['/api/inheritance', query],
            });
        },
    });

    const { updateSplitLevel: updateStoreSplitLevel } = useUpdateSplitLevel();

    const showSnackbar = useChangesSnackbar();
    const discardChanges = useDiscardChangesModule(Module.ProductLineSplit);

    const { data: valuesToUpdate } = useProductLineSplitOverviewSimulationRows();

    const [isUpdating, setIsUpdating] = useState(false);

    const sendUpdateQuery = useCallback(async () => {
        if (!valuesToUpdate) return;

        const payload = valuesToUpdate.flatMap(valueMapper);

        if (scope === ScopeEnum.STORE) {
            await updateStoreSplitLevel(Inheritance.Typed);
        }

        return mutateProductlineSplit({
            body: payload,
        });
    }, [mutateProductlineSplit, scope, updateStoreSplitLevel, valuesToUpdate]);

    const updateDeliveryProfile = useCallback(async () => {
        setIsUpdating(true);

        try {
            const response = await sendUpdateQuery();
            discardChanges();
            showSnackbar();
            return response;
        } catch {
            showSnackbar(true);
        } finally {
            setIsUpdating(false);
        }
    }, [discardChanges, sendUpdateQuery, showSnackbar]);

    return [updateDeliveryProfile, { loading: isUpdating }] as const;
};
