import { Close } from '@mui/icons-material';
import {
    Button,
    CSSObject,
    ListItem,
    ListItemText,
    Drawer as MuiDrawer,
    Skeleton,
    Stack,
    Theme,
    Typography,
    styled,
} from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import { groupBy } from 'lodash';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useAuditLogOverviewQuery } from 'src/hooks/audit-log/queries/useAuditLogOverviewQuery';
import { useIsAuditLogSupported } from 'src/hooks/audit-log/useIsAuditLogSupported';
import { useDrawerState } from 'src/hooks/useDrawerState';

export const AuditLogDrawer: FC = () => {
    const { auditLogDrawerOpen, setAuditLogDrawerOpen } = useDrawerState();
    const { data: auditLogs, loading, error } = useAuditLogOverviewQuery(auditLogDrawerOpen) ?? {};

    const isAuditLogSupported = useIsAuditLogSupported();

    useEffect(() => {
        if (!isAuditLogSupported) {
            setAuditLogDrawerOpen(false);
        }
    }, [isAuditLogSupported, setAuditLogDrawerOpen]);

    const groupedByDate = useMemo(
        () => groupBy(auditLogs, (auditLog) => auditLog.timeOfUpdate.split('T')[0]),
        [auditLogs]
    );

    const groupHeaders = useMemo(() => Object.keys(groupedByDate), [groupedByDate]);

    const closeDrawer = useCallback(() => {
        setAuditLogDrawerOpen(false);
    }, [setAuditLogDrawerOpen]);

    return (
        <DrawerWrapper>
            <Drawer open={auditLogDrawerOpen} variant="permanent" anchor="right">
                <Stack boxSizing={'border-box'} height="100%" p={2}>
                    <Stack direction="row" alignItems="center" gap={0.25}>
                        <StyledCloseButton
                            onClick={closeDrawer}
                            startIcon={<Close fontSize="small"></Close>}
                            size="small"
                        >
                            Close
                        </StyledCloseButton>
                    </Stack>
                    <Typography flexShrink={1} mt={3} mb={3} variant="h6" textAlign={'center'}>
                        Audit Logs
                    </Typography>
                    {error && <Typography variant="body2">Error loading audit logs: {error.message}</Typography>}
                    {loading && (
                        <Stack flex={1} gap={2} overflow={'auto'}>
                            <SkeletonAuditListItem></SkeletonAuditListItem>
                            <SkeletonAuditListItem></SkeletonAuditListItem>
                        </Stack>
                    )}
                    {!loading && !error && groupHeaders.length <= 0 && (
                        <Typography variant="body2">No audit logs to display</Typography>
                    )}
                    {!loading && !error && groupHeaders.length > 0 && (
                        <Stack flex={1} gap={2} overflow={'auto'}>
                            {groupHeaders.map((header) => {
                                const auditLogs = groupedByDate[header];
                                return (
                                    <StyledListItem key={header}>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body2" fontWeight={'500'}>
                                                    {format(new Date(header), 'LLLL d, yyyy')}
                                                </Typography>
                                            }
                                            secondary={
                                                <Stack mt={1} gap={2}>
                                                    {auditLogs.map((auditLog) => {
                                                        return (
                                                            <Stack key={auditLog.correlationId}>
                                                                <Stack gap={0}>
                                                                    <Typography variant="body2" color="common.grey">
                                                                        {auditLog.changesCount} change(s)
                                                                    </Typography>
                                                                    <Stack direction="row" alignItems={'center'}>
                                                                        <Typography
                                                                            fontWeight={500}
                                                                            fontSize={'14px'}
                                                                            color="common.grey"
                                                                            mr={0.5}
                                                                        >
                                                                            {format(
                                                                                new Date(auditLog.timeOfUpdate),
                                                                                'HH:mm '
                                                                            )}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.primary"
                                                                        >
                                                                            {auditLog.userId}
                                                                        </Typography>
                                                                    </Stack>
                                                                </Stack>
                                                            </Stack>
                                                        );
                                                    })}
                                                </Stack>
                                            }
                                            secondaryTypographyProps={{ component: 'div' }}
                                        />
                                    </StyledListItem>
                                );
                            })}
                        </Stack>
                    )}
                </Stack>
            </Drawer>
        </DrawerWrapper>
    );
};

const StyledListItem = styled(ListItem)`
  background-color: #fff;
  border-radius: 3px;
`;

const SkeletonAuditListItem = () => {
    return <Skeleton variant="rectangular" width={368} height={108} />;
};

const StyledCloseButton = styled(Button)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

const openedMixin = (theme: Theme): CSSObject => ({
    width: 400,
    right: 0,
    position: 'absolute',
    backgroundColor: '#C4C4C4',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    width: 0,
    right: 0,
    position: 'absolute',
    backgroundColor: '#C4C4C4',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeInOut,
        duration: 500,
    }),
    overflowX: 'hidden',
});

const DrawerWrapper = styled(motion.div)`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: transparent;
  height: 100vh;
  box-sizing: border-box;
`;

const Drawer = muiStyled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ open, theme }) => ({
    width: 300,
    height: '100vh',
    padding: 0,
    position: 'absolute',
    flexShrink: 0,
    right: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));
