import { Button, FormLabel, Paper, Popover, Stack, TextField, styled } from '@mui/material';
import { CustomCellEditorProps } from 'ag-grid-react';
import { isNumber } from 'lodash';
import { FC, useCallback, useLayoutEffect, useRef } from 'react';
import { ExtendedSalesCampaignDiscountType } from 'src/infrastructure/rest-api/api-types';
import { parseNumber } from 'src/utils/formatNumber';

interface DiscountCellEditorData {
    x: number | undefined;
    y: number | undefined;
    discountType: ExtendedSalesCampaignDiscountType | undefined;
}

export const DiscountCellEditor: FC<CustomCellEditorProps<unknown, DiscountCellEditorData>> = ({
    value,
    initialValue,
    onValueChange,
    eventKey,
    eGridCell,
    stopEditing,
}) => {
    const discountType = value?.discountType;
    const valueX = value?.x ?? '';
    const valueY = value?.y ?? '';

    const handleUpdate = useCallback(
        (property: keyof DiscountCellEditorData, newValue: string) => {
            const newValueAsNumber = parseNumber(newValue);

            if (isNumber(newValueAsNumber) && !isNaN(newValueAsNumber)) {
                const update = { [property]: newValueAsNumber };
                onValueChange({ x: value?.x, y: value?.y, discountType: value?.discountType, ...update });
            }
        },
        [onValueChange, value]
    );

    const inputRef = useRef<HTMLInputElement | null>(null);

    const handlePopoverClose = useCallback(() => {
        onValueChange(initialValue);
        stopEditing();
    }, [stopEditing, onValueChange, initialValue]);

    useLayoutEffect(() => {
        const eventKeyAsNumber = Number(eventKey);
        if (eventKey && !isNaN(eventKeyAsNumber)) {
            onValueChange({
                x: 1,
                y: eventKeyAsNumber,
                discountType: 'FIXED_PRICE',
            });
            return;
        }

        setTimeout(() => {
            inputRef.current?.select();
        });
    }, [eventKey, onValueChange]);

    const handleEnterKey = useCallback(
        (event: React.KeyboardEvent) => {
            if (event.key === 'Enter') {
                event.stopPropagation();
                stopEditing();
            }
        },
        [stopEditing]
    );

    return (
        <>
            {discountType === 'FIXED_PRICE' ? (
                <EditorTextField
                    inputRef={inputRef}
                    autoFocus
                    fullWidth
                    placeholder="Enter price"
                    onChange={(event) => handleUpdate('y', event.target.value)}
                    value={valueY ?? ''}
                />
            ) : (
                <>
                    {/* When discount type is set, we always have two values X and Y, one represents amount and the other represents price */}
                    {
                        <Popover
                            className="ag-custom-component-popup"
                            anchorEl={eGridCell}
                            open
                            sx={{ zIndex: 9 }}
                            onClose={handlePopoverClose}
                            onKeyDown={handleEnterKey}
                        >
                            <StyledPaper elevation={6}>
                                <Stack gap={'0.75rem'}>
                                    <Stack gap={'0.25rem'}>
                                        <StyledFormLabel>Amount</StyledFormLabel>
                                        <EditorTextField
                                            inputRef={inputRef}
                                            fullWidth
                                            placeholder="Enter amount"
                                            onChange={(event) => handleUpdate('x', event.target.value)}
                                            value={valueX ?? ''}
                                        />
                                    </Stack>
                                    <Stack gap={'0.25rem'}>
                                        <StyledFormLabel>
                                            {discountType === 'X_FOR_Y' ? 'Amount' : 'Price'}
                                        </StyledFormLabel>
                                        <EditorTextField
                                            fullWidth
                                            placeholder={discountType === 'X_FOR_Y' ? 'Enter amount' : 'Enter price'}
                                            onChange={(event) => handleUpdate('y', event.target.value)}
                                            value={valueY ?? ''}
                                        />
                                    </Stack>
                                    <Button variant="outlined" onClick={() => stopEditing()}>
                                        OK
                                    </Button>
                                </Stack>
                            </StyledPaper>
                        </Popover>
                    }
                </>
            )}
        </>
    );
};

const StyledPaper = styled(Paper)`
  background-color: #fff;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  font-size: 12px;
`;

const EditorTextField = styled(TextField)`
  .MuiInputBase-root {
    font-size: inherit;
    border-radius: 4px;
  }

  .Mui-focused fieldset {
    border-color: rgba(33, 150, 243, 0.4) !important;
  }

  .MuiInputBase-input {
    padding: 8px 14px;
  }
`;

const StyledFormLabel = styled(FormLabel)`
  font-size: inherit;
`;
