import { isNumber } from 'lodash';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Cell } from 'src/components/organisms/DataTable/types';
import { Module } from 'src/domain';
import { SalesCampaignOverviewRow } from 'src/domain/table/sales-campaign-overview.row';
import { findRow } from 'src/utils/ag-grid/findRow';
import { useUnsavedChangesModule } from '../unsaved-changes';
import { useSalesCampaignSimulationRows } from './useSalesCampaignSimulationRows';

function getNewData(cell: Cell, currentData: SalesCampaignOverviewRow) {
    switch (cell.column) {
        case 'discount.comment':
            return {
                discount: {
                    ...currentData.discount,
                    comment: cell.value,
                },
            };

        case 'discount.discountType':
            return {
                discount: {
                    ...currentData.discount,
                    valueX: cell.value === 'FIXED_PRICE' ? 1 : currentData.discount?.valueX,
                    discountType: cell.value || 'FIXED_PRICE',
                },
            };

        case 'discount':
            return {
                discount: {
                    ...currentData.discount,
                    discountType: cell.value?.discountType,
                    valueX: cell.value?.x,
                    valueY: cell.value?.y,
                },
            };

        case 'discountPercentage':
            return {
                discount: {
                    ...currentData.discount,
                    discountType: 'FIXED_PRICE',
                    valueX: 1,
                    valueY:
                        cell.value === null
                            ? null
                            : isNumber(currentData.recommendedRetailPriceLcy)
                              ? (currentData.recommendedRetailPriceLcy * (1 - cell.value / 100)).toFixed(2).toFloat()
                              : null,
                },
            };

        default:
    }

    if (cell.column) {
        return {
            [cell.column]: cell.value,
        };
    }

    return {};
}

export const useSalesCampaignPlanningCellValuesChanged = () => {
    const { id: salesCampaignId } = useParams();
    const { data: simulationData } = useSalesCampaignSimulationRows(Number(salesCampaignId));
    const [unsavedChanges, setUnsavedChanges] = useUnsavedChangesModule<SalesCampaignOverviewRow[]>(
        Module.SalesCampaignsPlanning
    );

    return useCallback(
        (cells: Cell[]) => {
            const newUnsavedChanges = [...(unsavedChanges ?? [])];

            cells.forEach((cell) => {
                if (cell.column) {
                    const { rowIndexToUpdate, simulationIndexToUpdate, currentData } = findRow(
                        cell,
                        newUnsavedChanges,
                        simulationData
                    );

                    const newData = getNewData(cell, currentData);

                    if (rowIndexToUpdate >= 0) {
                        newUnsavedChanges[rowIndexToUpdate] = {
                            ...newUnsavedChanges?.[rowIndexToUpdate],
                            ...newData,
                        };
                    } else {
                        newUnsavedChanges.push({
                            ...simulationData[simulationIndexToUpdate],
                            ...newData,
                        } as SalesCampaignOverviewRow);
                    }
                }
            });

            setUnsavedChanges(newUnsavedChanges);
        },
        [setUnsavedChanges, simulationData, unsavedChanges]
    );
};
