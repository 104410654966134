import styled from '@emotion/styled';
import { ArrowBack, Settings } from '@mui/icons-material';
import {
    ImageListItem,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Drawer as MuiDrawer,
} from '@mui/material';
import { CSSObject, Theme, styled as muiStyled } from '@mui/material/styles';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import devLogo from 'src/assets/img/logo-storeforecast-dev.svg';
import logo from 'src/assets/img/logo-storeforecast.svg';
import { DrawerItem } from 'src/components/molecules/DrawerItem/DrawerItem';
import { Module } from 'src/domain';
import { useDrawerState } from 'src/hooks/useDrawerState';
import { useIsDevEnv } from 'src/hooks/useIsDevEnv';
import { useRoutes } from 'src/useRoutes';
import { Sidebar } from '../../Sidebar/Sidebar';
import { DrawerSection } from './DrawerSection';

interface StyleProps {
    open: boolean | 1 | 0;
}

const drawerWidth = 300;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    backgroundColor: '#C4C4C4',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    '--drawer-closed-width': `calc(${theme.spacing(7)} + 1px)`,
    backgroundColor: '#C4C4C4',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `var(--drawer-closed-width)`,
});

const Drawer = muiStyled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    height: '100vh',
    padding: 0,
    position: 'absolute',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

export const DefaultDrawer: FC = () => {
    const { drawerOpen, setDrawerOpen, hasSidebar, setSettingsDrawerOpen } = useDrawerState();
    const navigate = useNavigate();
    const location = useLocation();
    const routes = useRoutes();
    const isDevEnv = useIsDevEnv();

    useEffect(() => {
        setDrawerOpen(false);
    }, [setDrawerOpen]);

    const isOpen = useMemo(() => (!hasSidebar ? true : drawerOpen), [drawerOpen, hasSidebar]);
    const handleSettingsDrawer = useCallback(() => {
        setSettingsDrawerOpen((cur) => !cur);
        if (!isOpen) setDrawerOpen((cur) => !cur);
    }, [isOpen, setDrawerOpen, setSettingsDrawerOpen]);
    const handleReturn = useCallback(() => navigate('/'), [navigate]);

    const shortTermRoutes = routes.filter((x) => x.type === 'short-term' && x.show !== false);
    const longTermRoutes = routes.filter((x) => x.type === 'long-term' && x.show !== false);
    const rangePlanningRoutes = routes.filter((x) => x.type === 'range-planning' && x.show !== false);
    const salesPlanningRoutes = routes.filter((x) => x.type === 'sale-planning' && x.show !== false);

    return (
        <StyledBox drawerWidth={drawerWidth}>
            <Drawer
                open={isOpen}
                variant="permanent"
                onMouseEnter={() => setDrawerOpen(true)}
                onMouseLeave={() => setDrawerOpen(false)}
            >
                <StyledList style={{ display: 'flex', flexDirection: 'column' }}>
                    {!hasSidebar && <ListItem sx={{ height: '40px' }}></ListItem>}
                    {isOpen && (
                        <StyledImageItem onClick={() => navigate('/')}>
                            {<StyledImage src={isDevEnv ? devLogo : logo} />}
                        </StyledImageItem>
                    )}
                    {!isOpen && (
                        <StyledImageItem onClick={() => navigate('/')}>
                            {
                                <StyledIconLogo
                                    src={isDevEnv ? '/storeforecast-icon-dev.svg' : '/storeforecast-icon.svg'}
                                />
                            }
                        </StyledImageItem>
                    )}
                    <StyledBackItem>
                        <StyledBackButton open={isOpen} onClick={handleReturn}>
                            <StyledIcon open={isOpen}>
                                <ArrowBack />
                            </StyledIcon>
                            {isOpen && <ListItemText sx={{ marginLeft: '10px' }} primary="Back to selection" />}
                        </StyledBackButton>
                    </StyledBackItem>

                    <DrawerSection dividerText="Long term" routes={longTermRoutes} />
                    <DrawerSection dividerText="Short term" routes={shortTermRoutes} />
                    {rangePlanningRoutes.length > 0 && (
                        <DrawerSection dividerText="Range planning" routes={rangePlanningRoutes} />
                    )}
                    {salesPlanningRoutes.length > 0 && (
                        <DrawerSection dividerText="Sales planning" routes={salesPlanningRoutes} />
                    )}
                    <SettingsWrapper>
                        <DrawerItem
                            icon={<Settings />}
                            open={isOpen}
                            title="Settings"
                            to={{ pathname: `/settings/${Module.ClusterManagement}`, search: location.search }}
                            sx={{ marginTop: 'auto !important' }}
                            onClick={handleSettingsDrawer}
                        />
                    </SettingsWrapper>
                </StyledList>
            </Drawer>
            <Sidebar />
        </StyledBox>
    );
};

const StyledBox = styled.div<{ drawerWidth: number }>`
  position: relative;
  z-index: 2;
  min-width: ${drawerWidth}px;
  background-color: transparent;
  height: 100vh;
  max-width: ${drawerWidth}px;
`;

const StyledList = styled(List)`
  height: 100%;
`;

const StyledBackItem = styled(ListItem)`
  display: 'block';
  transition: 'ease-in-out 0.5s';
  padding: 8px;
`;

const StyledBackButton = styled(ListItemButton)<StyleProps>`
  height: 40px;
  min-width: 40px;
  background-color: #fff;
  justify-content: ${({ open }) => (open ? 'initial' : 'center')};
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  :hover {
    background: #fff;
  }
`;

const StyledIcon = styled(ListItemIcon)<StyleProps>`
  min-width: 0px;
  margin-right: ${({ open }) => (open ? '3' : 'auto')};
  justify-content: 'center';
`;

const StyledImageItem = styled(ImageListItem)`
  height: 35px;
  min-height: 35px;
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

const StyledImage = styled.img`
  width: 150px;
  height: auto;
`;

const StyledIconLogo = styled.img`
  width: 30px;
  height: auto;
`;

const SettingsWrapper = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
