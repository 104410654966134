import styled from '@emotion/styled';
import { InfoOutlined } from '@mui/icons-material';
import { ColDef } from 'ag-grid-community';
import { FC } from 'react';
import Header from 'src/components/atoms/Header';
import { MonthSelector } from 'src/components/molecules/MonthSelector/MonthSelector';
import { DetailsContainer } from 'src/components/styled/DetailsContainer';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { Color, Headings } from 'src/domain';
import { useActiveMonth } from 'src/hooks/useActiveMonth';
import { DataTable, DataTableProps } from '../DataTable/DataTable';

interface DetailsTableProps extends Omit<DataTableProps, 'rows'> {
    title: string;
    rows: Map<string, DataTableProps['rows']> | undefined;
    wrapperClass?: string;
    message?: string;
    monthColumns: ColDef[];
}

const DetailsTable: FC<DetailsTableProps> = ({ monthColumns, rows, wrapperClass = '', ...datatableProps }) => {
    const [month] = useActiveMonth();

    const currentRows = rows?.get(month);

    return (
        <DetailsContainer data-testid="details-table">
            <Wrapper>
                <Header heading={Headings.h2}>{datatableProps.title}</Header>
                {datatableProps.message && (
                    <Message>
                        <InfoIcon />
                        {datatableProps.message}
                    </Message>
                )}
            </Wrapper>
            <MonthSelector months={monthColumns} />
            <TableWrapper className={`ag-theme-alpine-custom-compact ag-sum ${wrapperClass}`}>
                <DataTable autoSizeToFit {...datatableProps} rows={currentRows} />
            </TableWrapper>
        </DetailsContainer>
    );
};

export default DetailsTable;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1.5rem;
`;

const Message = styled.div`
  font-size: 10px;
  background-color: #f1f1f9;
  padding: 10px 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const InfoIcon = styled(InfoOutlined)`
  color: ${Color.antracit};
  font-size: 18px;
`;
