import Dexie, { EntityTable } from 'dexie';
import { useLiveQuery } from 'dexie-react-hooks';
import { useCallback, useMemo } from 'react';
import { AnnouncementsModel } from 'src/infrastructure/rest-api/api-types';
import { useAnnouncementsApiQuery } from './queries/useAnnouncementApiQuery';

export interface ReadAnnouncement {
    key: string;
}

export interface ReadAnnouncementsDatabase extends Dexie {
    readAnnouncements: EntityTable<ReadAnnouncement, 'key'>;
}

export function createReadAnnouncementsDatabase() {
    const db = new Dexie('ReadAnnouncementsDatabase') as ReadAnnouncementsDatabase;
    db.version(1).stores({
        readAnnouncements: 'key',
    });
    return db;
}

const db = createReadAnnouncementsDatabase();

export interface ReadAnnouncementModel extends AnnouncementsModel {
    read: boolean;
}

export const useAnnouncements = () => {
    const { data, loading, error, refetch } = useAnnouncementsApiQuery();
    const readAnnouncements = useLiveQuery(() => db.readAnnouncements.toArray(), []);

    const announcements: ReadAnnouncementModel[] = useMemo(() => {
        if (!data || !readAnnouncements) {
            return [];
        }

        return data.map((announcement) => {
            return {
                ...announcement,
                read: (readAnnouncements ?? []).some((read) => read.key === announcement.announcementId.toString()),
            };
        });
    }, [data, readAnnouncements]);

    const markRead = useCallback((announcementIds: number[]) => {
        return db.readAnnouncements.bulkAdd(announcementIds.map((key) => ({ key: key.toString() })));
    }, []);

    const cleanupRead = useCallback(() => {
        const activeAnnouncements = announcements.filter((announcement) => !announcement.resolved);
        const activeIds = activeAnnouncements.map((announcement) => announcement.announcementId);
        const readIds = (readAnnouncements ?? []).map((read) => parseInt(read.key, 10));

        const toRemove = readIds.filter((id) => !activeIds.includes(id)).map(String);
        return db.readAnnouncements.bulkDelete(toRemove);
    }, [announcements, readAnnouncements]);

    return {
        data: announcements,
        loading: loading || !readAnnouncements,
        error,
        refetch,
        markRead,
        cleanupRead,
    };
};
