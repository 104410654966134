import styled from '@emotion/styled';
import { East } from '@mui/icons-material';
import { Box, CSSObject, List, Modal, Drawer as MuiDrawer, Theme, Typography } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import { FC, useState } from 'react';
import Spacer from 'src/components/atoms/Spacer/Spacer';
import { Spacings } from 'src/domain';
import { useSnackbar } from 'src/hooks/snackbar/useSnackbar';
import { useCurrentStoresList } from 'src/hooks/store/useCurrentStoresList';
import { useTurnoverSaveChanges } from 'src/hooks/turnover/save-changes/useTurnoverSaveChanges';
import { useDrawerState } from 'src/hooks/useDrawerState';
import { numberWithSpaces } from 'src/utils/numberWithSpaces';
import { BulkEditDrawerSection, BulkEditRow } from './BulkEditDrawerSection';

const openedMixin = (theme: Theme): CSSObject => ({
    width: 300,
    right: 0,
    position: 'absolute',
    backgroundColor: '#C4C4C4',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    width: 0,
    right: 0,
    position: 'absolute',
    backgroundColor: '#C4C4C4',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeInOut,
        duration: 500,
    }),
    overflowX: 'hidden',
});

const Drawer = muiStyled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ open, theme }) => ({
    width: 300,
    height: '100vh',
    padding: 0,
    position: 'absolute',
    flexShrink: 0,
    right: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

export const BulkEditDrawer: FC = () => {
    const { bulkEditDrawerOpen, setBulkEditDrawerOpen } = useDrawerState();
    const [modalOpen, setModalOpen] = useState(false);
    const [originalTotalSales, setOriginalTotalSales] = useState(0);
    const [updatedTotalSales, setUpdatedTotalSales] = useState(0);
    const [changes, setChanges] = useState<BulkEditRow[]>([]);
    const stores = useCurrentStoresList();
    const showSnackbar = useSnackbar();

    const [saveChanges, { loading: isSaving }] = useTurnoverSaveChanges(changes);

    const handleCancel = () => {
        setBulkEditDrawerOpen(false);
    };

    const handleShowDetails = () => {
        setModalOpen(true);
    };

    const handleSave = () => {
        saveChanges()
            .then(() => {
                if (changes.length > 0) {
                    setBulkEditDrawerOpen(false);
                }
            })
            .catch(() => {
                showSnackbar('Failed to save changes', 'error');
            });
    };

    const handleChanges = (updatedChanges: BulkEditRow[], originalSales: number, updatedSales: number) => {
        setChanges(updatedChanges);
        setOriginalTotalSales(Math.round(originalSales));
        setUpdatedTotalSales(Math.round(updatedSales));
    };

    return (
        <>
            <StyledBox>
                <Drawer open={bulkEditDrawerOpen} variant="permanent">
                    <AnimatePresence>
                        {bulkEditDrawerOpen && (
                            <StyledList
                                style={{ display: 'flex', flexDirection: 'column' }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1, transition: { duration: 0.8, ease: 'easeInOut' } }}
                                exit={{ opacity: 0, transition: { duration: 0.3, ease: 'easeInOut' } }}
                            >
                                <BulkEditDrawerSection
                                    isSaving={isSaving}
                                    onCancel={handleCancel}
                                    onSave={handleSave}
                                    onShowDetails={handleShowDetails}
                                    onChanges={handleChanges}
                                />
                            </StyledList>
                        )}
                    </AnimatePresence>
                </Drawer>
            </StyledBox>

            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Wrapper>
                    {originalTotalSales !== updatedTotalSales && (
                        <>
                            <StyledTypography id="modal-modal-description">Total Expected Sales iV</StyledTypography>
                            <SalesWrapper>
                                <SalesBox style={{ textDecoration: 'line-through' }}>
                                    {numberWithSpaces(originalTotalSales)}
                                </SalesBox>
                                <East />
                                <SalesBox>{numberWithSpaces(updatedTotalSales)}</SalesBox>
                            </SalesWrapper>
                            <Spacer vertical spacing={Spacings.medium} />
                        </>
                    )}
                    <StoresListDescription>
                        <StyledTypography id="modal-modal-description">Stores List</StyledTypography>
                        <Description>
                            These stores will be effected by the changes you made in the bulk edit mode.
                        </Description>
                    </StoresListDescription>
                    <StoreListWrapper>
                        {_.sortBy(stores, ['storeName'])?.map((store) => (
                            <Store key={store.id}>{store.storeName}</Store>
                        ))}
                    </StoreListWrapper>
                </Wrapper>
            </Modal>
        </>
    );
};

const StyledBox = styled(motion.div)`
  position: absolute;
  bottom: 0;
  right: 0;
  min-width: 300px;
  background-color: transparent;
  height: 100vh;
  max-width: 300px;
`;

// biome-ignore lint/suspicious/noExplicitAny: we need to use any
const StyledList = styled(motion.create(List as any))`
  height: 100%;
`;

const Wrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 600px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 24px;
  padding: 30px 20px;
`;

const SalesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const SalesBox = styled(Box)`
  text-align: center;
  padding: 20px;
  width: 100px;
  background-color: #f7f7f7;
  border-radius: 5px;
  margin-top: 5px;
`;

const StoreListWrapper = styled.div`
  max-height: 250px;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 600;
  padding-left: 10px;
`;

const Store = styled.span`
  margin-bottom: 2px;
`;

const StyledTypography = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
`;

const StoresListDescription = styled.div`
  padding-bottom: 10px;
`;

const Description = styled.p`
  font-size: 12px;
  margin: 0;
`;
