import { datadogRum } from '@datadog/browser-rum';
import styled from '@emotion/styled';
import { Delete, Edit, VerifiedOutlined } from '@mui/icons-material';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { GridApi, GridReadyEvent, GroupCellRendererParams } from 'ag-grid-enterprise';
import { groupBy } from 'lodash';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { GroupedOption } from 'src/components/atoms/Select/Select';
import { CreateClusterDialog } from 'src/components/organisms/CreateClusterDialog/CreateClusterDialog';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { Cell } from 'src/components/organisms/DataTable/types';
import { DeleteDialog } from 'src/components/organisms/DeleteDialog/DeleteDialog';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { Module } from 'src/domain';
import { ClusterManagementUnsavedChanges } from 'src/domain/cluster-management/ClusterManagementUnsavedChanges';
import { ClusterManagementRow } from 'src/domain/models/cluster-management/cluster-management-row';
import { useChainId, useHasUnsavedChanges, useType, useUnsavedChangesModule } from 'src/hooks';
import { useClusterManagementSimulationRows } from 'src/hooks/cluster-management/simulation/useClusterManagementSimulationRows';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useSnackbar } from 'src/hooks/snackbar/useSnackbar';
import { useCurrentId } from 'src/hooks/useCurrentId';
import { UpdateClusterInput } from 'src/infrastructure/rest-api/api-types';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { ClusterManagementTopBar } from './ClusterManagementTopBar';
import { defaultColDef, defaultColumns } from './data/columns';

interface AgEditClusterEvent {
    clusterId: string;
}

export const ClusterManagementView: FC = () => {
    const { data: rows, clusters, loading } = useClusterManagementSimulationRows();
    const [createClusterDialogOpen, setCreateClusterDialogOpen] = useState(false);
    const hasUnsavedChanges = useHasUnsavedChanges(Module.ClusterManagement);
    const discardChanges = useDiscardChangesModule(Module.ClusterManagement);
    const gridApiRef = useRef<GridApi | null>(null);
    const [editingClusterId, setEditingClusterId] = useState<string | null>(null);
    const [deletingClusterId, setDeletingClusterId] = useState<string | null>(null);
    const showSnackbar = useSnackbar();
    const type = useType();
    const currentId = useCurrentId();
    const chainId = useChainId();
    const compositePartner = useCompositePartner();

    const [saveClusterData, { loading: isSaving }] = useApiMutation('/api/clusters', 'put', {
        update(data, _variables, queryClient) {
            queryClient.setQueryData([`/api/clusters/partners/${compositePartner?.id}`, undefined], data);
        },
    });

    const [unsavedChanges, setUnsavedChanges] = useUnsavedChangesModule<ClusterManagementUnsavedChanges>(
        Module.ClusterManagement
    );

    const editingCluster = useMemo(() => {
        if (editingClusterId === null) {
            return null;
        }
        return clusters?.find((cluster) => cluster.clusterId === Number(editingClusterId));
    }, [editingClusterId, clusters]);

    const deletingCluster = useMemo(() => {
        if (deletingClusterId === null) {
            return null;
        }
        return clusters?.find((cluster) => cluster.clusterId === Number(deletingClusterId));
    }, [deletingClusterId, clusters]);

    const deleteCluster = () => {
        // move all clusters to default cluster
        const defaultCluster = clusters?.find((cluster) => cluster.isDefault);

        if (!defaultCluster) {
            showSnackbar('Cannot delete cluster, there is no default cluster to move stores to.', 'error');
            setDeletingClusterId(null);
            return;
        }

        const updatedStores =
            rows?.map((row) => {
                if (row.cluster?.clusterId === deletingCluster?.clusterId) {
                    return {
                        ...row,
                        cluster: defaultCluster,
                    };
                }
                return row;
            }) ?? [];

        // remove cluster from list
        const updatedClusters = clusters?.filter((cluster) => cluster.clusterId !== deletingCluster?.clusterId) ?? [];

        // update unsaved changes
        setUnsavedChanges({
            clusters: updatedClusters,
            rows: updatedStores,
        });

        setDeletingClusterId(null);
    };

    useEffect(() => {
        const handleEditCluster = (event: CustomEventInit<AgEditClusterEvent>) => {
            setEditingClusterId(event.detail!.clusterId);
            setCreateClusterDialogOpen(true);
        };

        const handleDeleteCluster = (event: CustomEventInit<AgEditClusterEvent>) => {
            setDeletingClusterId(event.detail!.clusterId);
        };

        window.addEventListener('ag-edit-cluster', handleEditCluster);
        window.addEventListener('ag-delete-cluster', handleDeleteCluster);

        return () => {
            window.removeEventListener('ag-edit-cluster', handleEditCluster);
            window.removeEventListener('ag-delete-cluster', handleDeleteCluster);
        };
    }, []);

    const handleCellValuesChanged = (cells: Cell[]) => {
        const newClusters = structuredClone(clusters ?? []);
        const newRows = structuredClone(rows ?? []);
        for (const cell of cells) {
            const row = newRows.find((r) => r.rowId === cell.rowId);
            const newCluster = newClusters?.find((cluster) => cluster.clusterId === Number(cell.value.clusterId));
            if (row && newCluster) {
                const oldCluster = newClusters.find((cluster) => cluster.clusterId === row.cluster?.clusterId);
                oldCluster?.stores.splice(oldCluster.stores.indexOf(row.storeId), 1);
                newCluster.stores.push(row.storeId);
                row.cluster = newCluster;
            }
        }

        setUnsavedChanges({
            ...unsavedChanges,
            rows: newRows,
        });
    };

    const handleGridReady = (event: GridReadyEvent) => {
        gridApiRef.current = event.api;
    };

    const handleExpandAll = () => {
        gridApiRef.current?.expandAll();
    };

    const handleCollapseAll = () => {
        gridApiRef.current?.collapseAll();
    };

    const cancelCreateClusterDialog = () => {
        setCreateClusterDialogOpen(false);
        setEditingClusterId(null);
    };

    const handleCreateCluster = (clusterName: string, clusterDescription: string = '') => {
        if (editingCluster) {
            const unsavedClusters = clusters ?? [];
            const clusterToUpdate = unsavedClusters.find((cluster) => cluster.clusterId === editingCluster.clusterId);

            if (clusterToUpdate?.clusterName === clusterName && clusterToUpdate?.description === clusterDescription) {
                // no changes
                setEditingClusterId(null);
                setCreateClusterDialogOpen(false);
                return;
            }

            const index = unsavedClusters?.indexOf(clusterToUpdate!);
            unsavedClusters[index] = {
                ...clusterToUpdate!,
                clusterName,
                description: clusterDescription,
            };

            setUnsavedChanges({
                ...unsavedChanges,
                clusters: unsavedClusters,
            });

            setEditingClusterId(null);
        } else {
            const unsavedClusters = clusters ?? [];
            const newClusterId = -1 * (Math.max(...unsavedClusters.map((cluster) => cluster.clusterId ?? 0)) + 1);
            unsavedClusters.push({
                clusterId: newClusterId,
                clusterName,
                description: clusterDescription,
                isDefault: false,
                stores: [],
            });
            setUnsavedChanges({
                ...unsavedChanges,
                clusters: unsavedClusters,
            });
        }
        setCreateClusterDialogOpen(false);
    };

    const saveChanges = () => {
        if (!rows || !clusters) {
            return;
        }

        const nonEmptyRows = rows.filter((row) => !row.rowId.startsWith('empty'));
        const storesByClusterId = groupBy(nonEmptyRows, (row) => row.cluster?.clusterId);
        const updatedClusters = Object.entries(storesByClusterId).reduce<UpdateClusterInput[]>(
            (acc, [clusterId, stores]) => {
                const cluster = clusters.find((c) => c.clusterId === Number(clusterId));

                if (!cluster) {
                    datadogRum.addError(new Error(`Cluster not found`), {
                        clusterId,
                        chainId,
                        compositePartnerId: compositePartner?.id,
                        groupType: type,
                        groupId: currentId,
                    });
                    return acc;
                }

                acc.push({
                    clusterId:
                        typeof cluster.clusterId === 'number' && cluster.clusterId >= 0 ? cluster.clusterId : null,
                    clusterName: cluster.clusterName,
                    description: cluster.description ?? '',
                    isDefault: cluster.isDefault,
                    stores: stores.map((store) => store.storeId),
                });

                return acc;
            },
            []
        );

        const emptyClusters = clusters
            .filter((cluster) => !storesByClusterId[cluster.clusterId!])
            .map((cluster) => ({
                clusterId: typeof cluster.clusterId === 'number' && cluster.clusterId >= 0 ? cluster.clusterId : null,
                clusterName: cluster.clusterName,
                description: cluster.description ?? '',
                isDefault: cluster.isDefault,
                stores: [],
            }));

        updatedClusters.push(...emptyClusters);

        saveClusterData({
            body: updatedClusters,
        })
            .then(() => {
                showSnackbar('Changes saved successfully', {
                    variant: 'success',
                    preventDuplicate: true,
                });
                discardChanges();
            })
            .catch(() => {
                showSnackbar('Failed to save changes', {
                    variant: 'error',
                    preventDuplicate: true,
                });
            });
    };

    const columns = useMemo(() => {
        return defaultColumns.map((column) => {
            if (column.field === 'cluster') {
                return {
                    ...column,
                    cellEditorParams: {
                        selectOptions: (clusters ?? []).map((cluster) => ({
                            display: cluster.clusterName,
                            value: cluster.clusterId!.toString(),
                            isHeader: false,
                        })) as GroupedOption[],
                    },
                };
            }

            return column;
        });
    }, [clusters]);

    return (
        <>
            <CreateClusterDialog
                key={editingCluster ? `edit-${editingCluster.clusterId}` : undefined}
                isEditing={!!editingCluster}
                initialClusterName={editingCluster?.clusterName}
                initialClusterDescription={editingCluster?.description}
                open={createClusterDialogOpen}
                onOk={handleCreateCluster}
                onCancel={cancelCreateClusterDialog}
            />
            <DeleteDialog
                open={!!deletingCluster}
                title="Delete cluster?"
                content="Are you sure you want to delete the cluster?"
                onCancel={() => setDeletingClusterId(null)}
                onDelete={deleteCluster}
            />
            <Container>
                <ClusterManagementTopBar
                    isDiscardButtonDisabled={!hasUnsavedChanges}
                    isSaveButtonDisabled={!hasUnsavedChanges}
                    isSaving={isSaving}
                    onAddCluster={() => setCreateClusterDialogOpen(true)}
                    onCollapseAll={handleCollapseAll}
                    onDiscardChanges={discardChanges}
                    onExpandAll={handleExpandAll}
                    onSaveChanges={saveChanges}
                />
                <TableWrapper flex={1} className="ag-theme-alpine-custom-compact">
                    <DataTable
                        onCellValuesChanged={handleCellValuesChanged}
                        isNumberTable={false}
                        loading={loading}
                        onGridReady={handleGridReady}
                        columns={columns}
                        defaultColDef={defaultColDef}
                        rows={rows}
                        groupDisplayType="groupRows"
                        groupRowRendererParams={{
                            innerRenderer: ClusterManagementRowGroupCell,
                            suppressCount: true,
                        }}
                    ></DataTable>
                </TableWrapper>
            </Container>
        </>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .ag-row-group {
    .ag-group-value {
        display: flex;
        width: 100%;
    }
  }
`;

const ClusterManagementRowGroupCell: FC<GroupCellRendererParams<ClusterManagementRow>> = ({ node }) => {
    const childNode = node?.childrenAfterGroup?.first();
    const value = childNode?.data?.cluster;

    const onEdit = () => {
        if (!value?.clusterId) {
            return;
        }
        window.dispatchEvent(
            new CustomEvent<AgEditClusterEvent>('ag-edit-cluster', {
                detail: {
                    clusterId: value.clusterId.toString(),
                },
            })
        );
    };

    const onDelete = () => {
        if (!value?.clusterId) {
            return;
        }
        window.dispatchEvent(
            new CustomEvent<AgEditClusterEvent>('ag-delete-cluster', {
                detail: {
                    clusterId: value.clusterId.toString(),
                },
            })
        );
    };

    const storeCount = node.childrenAfterGroup?.filter((node) => !node.data?.rowId.startsWith('empty')).length;

    return (
        <Stack direction="row" alignItems="center" gap="0.5rem" width="100%">
            <Stack direction="row" gap="0.5rem" alignItems="center">
                <Typography fontWeight={'bold'}>{value?.clusterName}</Typography>
                <Typography>({storeCount})</Typography>
                {value?.isDefault && (
                    <Tooltip title="Default cluster">
                        <VerifiedOutlined color="primary" fontSize={'small'} />
                    </Tooltip>
                )}
                <Typography fontStyle={'italic'}>{value?.description}</Typography>
            </Stack>
            <Stack direction="row" sx={{ ml: 'auto' }}>
                <Tooltip title={`Edit cluster ${value?.clusterName}`}>
                    <IconButton onClick={onEdit} size="small" color="primary">
                        <Edit />
                    </IconButton>
                </Tooltip>

                <Tooltip
                    title={
                        value?.isDefault ? 'Default cluster cannot be deleted' : `Delete cluster ${value?.clusterName}`
                    }
                >
                    <span>
                        <IconButton disabled={value?.isDefault} onClick={onDelete} size="small" color="error">
                            <Delete />
                        </IconButton>
                    </span>
                </Tooltip>
            </Stack>
        </Stack>
    );
};
