import { isNumber } from 'lodash';
import { AnnouncementRow } from 'src/domain/table/announcement.row';
import { ReadAnnouncementModel } from 'src/hooks/announcements/useAnnouncements';
import { announcementModuleToModule } from 'src/utils/modules';

export function mapAnnouncements(data: ReadAnnouncementModel[] | undefined): AnnouncementRow[] {
    if (!data) return [];

    return data.reduce<AnnouncementRow[]>((acc, announcement) => {
        const id = announcement.announcementId;
        const title = announcement.title;
        const description = announcement.description;
        const startDate = announcement.created;
        const modified = announcement.modified;
        const resolved = announcement.resolved;
        const read = announcement.read;
        const type = announcement.announcementType;
        const module = announcementModuleToModule(announcement.moduleEnum);

        if (!announcement || !isNumber(id) || !title || !type || !startDate) {
            return acc;
        }

        const created = new Date(startDate);
        const modifiedDate = modified ? new Date(modified) : undefined;
        const resolvedDate = resolved ? new Date(resolved) : undefined;

        acc.push({
            id: id,
            title: title,
            description: description ?? '',
            type: type,
            module: module ?? null,
            created: created,
            modified: modifiedDate,
            resolved: resolvedDate,
            read,
        });

        return acc;
    }, []);
}
